import React, { useEffect, useRef } from "react";
import useLoader from "../../../hooks/useLoader";
import {
  getBlogById,
  getBlogComments,
} from "../../../redux/actions/blog.action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultImage from "../../../assets/images/defaultImage.svg";
import moment from "moment";

const ViewBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { isLoading, setLoader, Loader } = useLoader();
  const { id } = useParams(); // Extract 'id' from the URL
  const specificBlogData = useSelector(
    (state) => state.BlogReducer.blog_by_id_data?.data[0]
  );
  const blogCommentsData = useSelector(
    (state) => state.BlogReducer.blogs_comments_data?.data
  );

  useEffect(() => {
    if (id) {
      dispatch(getBlogById(id));
      dispatch(getBlogComments());
    }
  }, []);

  return (
    <div className="p-6 max-w-[1600px] m-auto">
      <div className="flex items-center justify-between mb-8">
        <p className="text-[20px] font-[500]">View Blogs</p>
        <p
          className="text-[18px] text-[#232323] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <span>&lt; </span>Back
        </p>
      </div>
      <div className="mb-6">
        <div className="mb-10">
          <img
            src={specificBlogData?.blog_img}
            className="max-h-[450px] w-full object-cover"
          />
        </div>

        <div className="0">
          <p className="font-[500] text-[32px] leading-[48px] mb-6">
            Why Healthcare Jobs Are Good: A Closer Look
          </p>
          <div
            className="blog-content prose lg:prose-xl editor-content"
            dangerouslySetInnerHTML={{ __html: specificBlogData?.blog_content }}
          ></div>
          {/* <div
            className="blog-content prose lg:prose-xl"
            dangerouslySetInnerHTML={{
              __html: specificBlogData?.blog_content,
            }}
          ></div> */}
        </div>
      </div>
      <div>
        <p className="font-[500] text-[24px] leading-[35px] mb-6">Comments</p>
        <hr className="mb-6" />
        <div className="flex gap-6 flex-col">
          {blogCommentsData?.map((comment) => (
            <div className="flex gap-8">
              <img src={defaultImage} className="self-start" />
              <div className="flex flex-col gap-4 w-full">
                <div className="flex gap-[10px] items-center">
                  <p className="text-[20px] text-[#262626]">
                    {comment.fullname}
                  </p>
                  <span className="size-[6px] rounded-full bg-[#989898]" />
                  <p className="text-[20px] text-[#989898]">
                    {" "}
                    {moment(comment.created_at).fromNow()}
                  </p>
                </div>
                <p className="text-[20px] leading-[35px] text-[#989898]">
                  {comment.comment}
                </p>
                <hr />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewBlog;
