import React, { useEffect, useState } from "react";
import EditIconImg from "../../../assets/images/editIcon.png";
import DeleteIconImg from "../../../assets/images/deleteIcon.png";
import { useNavigate } from "react-router-dom";
import {
  deleteBlog,
  deleteBlogSuccess,
} from "../../../redux/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../modal/Modal";

import moment from "moment";
const BlogGrid = ({ blogData, setIsBlogDeleted }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deleteSuccessIndicator = useSelector(
    (state) => state.BlogReducer.blogs_delete_data
  );

  useEffect(() => {
    if (deleteSuccessIndicator && deleteSuccessIndicator.status == 200) {
      setIsBlogDeleted(true);
      deleteBlogSuccess(null);
      setIsModalOpen(false);
    }
  }, [deleteSuccessIndicator]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    dispatch(deleteBlog(blogData?.id));
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="w-full md:max-w-[359px]">
      <div className="border border-[#D9D9D9] p-[20px] w-full 786:max-w-[359px] h-[437px] rounded-xl relative">
        <img
          className="w-full md:w-80 h-64 object-cover rounded-md mb-4 cursor-pointer"
          src={blogData.blog_img}
          alt=""
          onClick={() => navigate(`../viewBlog/${blogData.id}`)}
        />
        <div className="flex flex-col item-center">
          <div
            className="font-poppins text-[18px] leading-[27px] text-[#003C9D] font-medium cursor-pointer mb-2 text-ellipsis line-clamp-2"
            onClick={() => navigate(`../viewBlog/${blogData.id}`)}
          >
            {blogData.blog_title}
          </div>
          <div className="flex flex-col absolute bottom-12">
            <span className="font-poppins text-[16px] leading-[24px] text-[#5C616E] font-normal mb-2">
              {blogData.categoryname} |{" "}
              {moment(blogData?.published_date).format("MMM DD, YYYY")}
            </span>
          </div>
          <div className="flex flex-row gap-4 absolute bottom-6">
            <img
              className="w-[20px] h-[20px] cursor-pointer"
              src={EditIconImg}
              alt=""
              onClick={() => navigate(`../editBlog/${blogData?.id}`)}
            />
            <img
              className="w-[20px] h-[20px] cursor-pointer"
              src={DeleteIconImg}
              alt=""
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        confirmAction={openModal}
        title="Delete"
      >
        <p>Are you sure you want to delete this blog?</p>
      </Modal>
    </div>
  );
};

export default BlogGrid;