import React from "react";

const Modal = ({ isOpen, onClose, title, children, confirmAction }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-transparent/40  bg-opacity-70">
      <div className="bg-white rounded-lg shadow-lg w-1/3">
        <div className="p-4">
          <h2 className="text-lg font-semibold">{title}</h2>
        </div>
        <div className="p-4">{children}</div>
        <div className="p-4  text-right flex gap-5 justify-end">
          <button onClick={onClose} className="orangeButtonSecondary">
            Cancel
          </button>

          <button
            className="orangeButton"
            onClick={() => {
              confirmAction();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
