import React, { useState, useEffect } from "react";
import BlogGrid from "../../../components/adminPortal/Blogs/BlogGrid";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../../redux/actions/blog.action";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";

const BlogsMain = () => {
  const dispatch = useDispatch();
  const blogdata = useSelector((state) => state.BlogReducer.getblog_data);
  const pageSize = useSelector((state) => state.BlogReducer.page_size);
  const current_page = useSelector((state) => state.BlogReducer.current_page);
  const [currentPage, setCurrentPage] = useState(current_page);
  const navigate = useNavigate();
  const recentBlogsLimit = useSelector(
    (state) => state.BlogReducer.recent_blog_limit
  );
  const latestPosts = useSelector(
    (state) => state.BlogReducer.recent_blogs_data
  );
  const [isBlogDeleted, setIsBlogDeleted] = useState(false);
  //----------------------------------------------------------USE EFFECT FOR GET  Blog-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getBlog({ pageSize, currentPage }));
    if (isBlogDeleted) {
      setIsBlogDeleted(false);
    }
  }, [currentPage, isBlogDeleted]);
  //----------------------------------------------------------USE EFFECT FOR GET  Blog-----------------------------------------------------------------//

  const handleDeleteState = (e) => {
    setIsBlogDeleted(true);
  };
  return (
    <div className="max-w-[1600px] m-auto p-6 pt-10 flex flex-col gap-6">
      <div className="flex justify-between items-center mb-4">
        <p className="font-[500] text-[20px]">Blogs</p>
        <button
          className="orangeButton"
          onClick={() => {
            navigate("../addBlog");
          }}
        >
          Add Blogs
        </button>
      </div>
      <hr className="mb-6" />
      <div className="flex flex-wrap justify-center gap-5 items-center">
        {blogdata &&
          blogdata?.data?.map((blogItem) => (
            <BlogGrid
              blogData={blogItem}
              setIsBlogDeleted={handleDeleteState}
            />
          ))}
      </div>
      <div className="flex justify-between flex-col 991:flex-row gap-5">
        <p>
          Showing {blogdata?.data?.length} of {blogdata?.total_pages * 8}
        </p>
        <div>
          <Pagination
            totalPages={blogdata?.total_pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogsMain;
