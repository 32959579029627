// -------------------------------------------------------------------- All Imports Starts ------------------------------------------------------------------------------------------//
import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
// -------------------------------------------------------------------- All Imports Ends ------------------------------------------------------------------------------------------//

const BlogRoute = () => {
  const navigate = useNavigate();
  let authenticated = false;
  let username;
  let password;

  if (
    sessionStorage.getItem("blogUsername") ||
    sessionStorage.getItem("blogPassword")
  ) {
    if (
      sessionStorage.getItem("blogUsername") == "admin" &&
      sessionStorage.getItem("blogPassword") == "admin"
    ) {
      authenticated = true;
    }
  } else if (
    !sessionStorage.getItem("blogUsername") ||
    !sessionStorage.getItem("blogPassword")
  ) {
    username = prompt("Enter your username:");
    password = prompt("Enter your password:", "", { type: "password" });

    if (username && password) {
      sessionStorage.setItem("blogUsername", username);
      sessionStorage.setItem("blogPassword", password);
      if (username == "admin" && password == "admin") {
        authenticated = true;
      }
      // Handle login logic here, such as sending the credentials to a server
    } else {
      navigate("../");
    }
  }

  // -------------------------------------------------------------------- In Return Block, Checking Valid Routes for Admin ------------------------------------------------------------------//
  return authenticated == true ? (
    // <div className=" flex bg-[#F5F5F5] gap-5 px-[30px] py-[32px]">
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default BlogRoute;
