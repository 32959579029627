import {
  GET_BLOG,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_BLOG_BY_ID,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_FAILURE,
  GET_RECENT_BLOGS,
  GET_RECENT_BLOGS_SUCCESS,
  GET_RECENT_BLOGS_FAILURE,
  ADD_BLOG_COMMENT,
  ADD_BLOG_COMMENT_SUCCESS,
  ADD_BLOG_COMMENT_FAILURE,
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  EDIT_BLOG,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  GET_BLOG_COMMENTS,
  GET_BLOG_COMMENTS_SUCCESS,
  GET_BLOG_COMMENTS_FAILURE,
  DELETE_BLOGS,
  DELETE_BLOGS_SUCCESS,
  DELETE_BLOGS_FAILURE,
} from "../ActionTypes";

const initialState = {
  loader: true,
  getblog_data: null,
  getblog_error: null,
  recent_blog_limit: 5,
  blog_by_id_loader: true,
  blog_by_id_data: null,
  blog_by_id_error: null,
  recent_blogs_loader: true,
  recent_blogs_data: null,
  recent_blogs_error: null,
  page_size: 8,
  current_page: 1,
  blogs_comments_loader: null,
  blogs_comments_data: null,
  blogs_comments_error: null,
  blogs_add_loader: null,
  blogs_add_data: null,
  blogs_add_error: null,
  blogs_edit_loader: null,
  blogs_edit_data: null,
  blogs_edit_error: null,
  blogs_comments_loader: null,
  blogs_comments_data: null,
  blogs_comments_error: null,
  blogs_delete_loader: null,
  blogs_delete_data: null,
  blogs_delete_error: null,
};
const BlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG:
      return {
        ...state,
        loader: true,
      };
    case GET_BLOG_SUCCESS:
      return {
        ...state,
        loader: false,
        getblog_data: action?.payload,
      };
    case GET_BLOG_FAILURE:
      return {
        ...state,
        loader: false,
        getblog_error: action?.payload,
      };
    case GET_BLOG_BY_ID:
      return {
        ...state,
        blog_by_id_loader: true,
      };
    case GET_BLOG_BY_ID_SUCCESS:
      return {
        ...state,
        blog_by_id_loader: false,
        blog_by_id_data: action?.payload,
      };
    case GET_BLOG_BY_ID_FAILURE:
      return {
        ...state,
        blog_by_id_loader: false,
        blog_by_id_error: action?.payload,
      };
    case GET_RECENT_BLOGS:
      return {
        ...state,
        recent_blogs_loader: true,
      };
    case GET_RECENT_BLOGS_SUCCESS:
      return {
        ...state,
        blog_by_id_loader: false,
        recent_blogs_data: action?.payload,
      };
    case GET_RECENT_BLOGS_FAILURE:
      return {
        ...state,
        blog_by_id_loader: false,
        recent_blogs_error: action?.payload,
      };
    case ADD_BLOG_COMMENT:
      return {
        ...state,
        blogs_comments_loader: true,
      };
    case ADD_BLOG_COMMENT_SUCCESS:
      return {
        ...state,
        blogs_comments_loader: false,
        blogs_comments_data: action?.payload,
      };
    case ADD_BLOG_COMMENT_FAILURE:
      return {
        ...state,
        blogs_comments_loader: false,
        blogs_comments_error: action?.payload,
      };

    case ADD_BLOG:
      return {
        ...state,
        blogs_add_loader: true,
      };
    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        blogs_add_loader: false,
        blogs_add_data: action?.payload,
      };
    case ADD_BLOG_FAILURE:
      return {
        ...state,
        blogs_add_loader: false,
        blogs_add_error: action?.payload,
      };

    case GET_BLOG_COMMENTS:
      return {
        ...state,
        blogs_comments_loader: true,
      };
    case GET_BLOG_COMMENTS_SUCCESS:
      return {
        ...state,
        blogs_comments_loader: false,
        blogs_comments_data: action?.payload,
      };
    case GET_BLOG_COMMENTS_FAILURE:
      return {
        ...state,
        blogs_comments_loader: false,
        blogs_comments_error: action?.payload,
      };

    case EDIT_BLOG:
      return {
        ...state,
        blogs_edit_loader: true,
      };
    case EDIT_BLOG_SUCCESS:
      return {
        ...state,
        blogs_edit_loader: false,
        blogs_edit_data: action?.payload,
      };
    case EDIT_BLOG_FAILURE:
      return {
        ...state,
        blogs_edit_loader: false,
        blogs_edit_error: action?.payload,
      };

    case DELETE_BLOGS:
      return {
        ...state,
        blogs_delete_loader: true,
      };
    case DELETE_BLOGS_SUCCESS:
      return {
        ...state,
        blogs_delete_loader: false,
        blogs_delete_data: action?.payload,
      };
    case DELETE_BLOGS_FAILURE:
      return {
        ...state,
        blogs_delete_loader: false,
        blogs_delete_error: action?.payload,
      };

    default:
      return state;
  }
};

export default BlogReducer;
