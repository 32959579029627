import {
  GET_BLOG,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_BLOG_BY_ID,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_FAILURE,
  GET_RECENT_BLOGS,
  GET_RECENT_BLOGS_SUCCESS,
  GET_RECENT_BLOGS_FAILURE,
  ADD_BLOG_COMMENT,
  ADD_BLOG_COMMENT_SUCCESS,
  ADD_BLOG_COMMENT_FAILURE,
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  EDIT_BLOG,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  DELETE_BLOGS,
  DELETE_BLOGS_SUCCESS,
  DELETE_BLOGS_FAILURE,
  GET_BLOG_COMMENTS,
  GET_BLOG_COMMENTS_SUCCESS,
  GET_BLOG_COMMENTS_FAILURE,
} from "../ActionTypes";

export const getBlog = (payload) => {
  return {
    type: GET_BLOG,
    payload: payload,
  };
};
export const getBlogSuccess = (payload) => {
  return {
    type: GET_BLOG_SUCCESS,
    payload: payload,
  };
};
export const getBlogFailure = (payload) => {
  return {
    type: GET_BLOG_FAILURE,
    payload: payload,
  };
};

export const getBlogById = (payload) => {
  return {
    type: GET_BLOG_BY_ID,
    payload: payload,
  };
};
export const getBlogByIdSuccess = (payload) => {
  return {
    type: GET_BLOG_BY_ID_SUCCESS,
    payload: payload,
  };
};
export const getBlogByIdFailure = (payload) => {
  return {
    type: GET_BLOG_BY_ID_FAILURE,
    payload: payload,
  };
};

export const getRecentBlogs = (payload) => {
  return {
    type: GET_RECENT_BLOGS,
    payload: payload,
  };
};
export const getRecentBlogsSuccess = (payload) => {
  return {
    type: GET_RECENT_BLOGS_SUCCESS,
    payload: payload,
  };
};
export const getRecentBlogsFailure = (payload) => {
  return {
    type: GET_RECENT_BLOGS_FAILURE,
    payload: payload,
  };
};
export const addBlogComment = (payload) => {
  return {
    type: ADD_BLOG_COMMENT,
    payload: payload,
  };
};
export const addBlogCommentSuccess = (payload) => {
  return {
    type: ADD_BLOG_COMMENT_SUCCESS,
    payload: payload,
  };
};
export const addBlogCommentFailure = (payload) => {
  return {
    type: ADD_BLOG_COMMENT_FAILURE,
    payload: payload,
  };
};

export const addBlog = (payload) => {
  return {
    type: ADD_BLOG,
    payload: payload,
  };
};
export const addBlogSuccess = (payload) => {
  return {
    type: ADD_BLOG_SUCCESS,
    payload: payload,
  };
};
export const addBlogFailure = (payload) => {
  return {
    type: ADD_BLOG_FAILURE,
    payload: payload,
  };
};

export const editBlog = (payload) => {
  return {
    type: EDIT_BLOG,
    payload: payload,
  };
};
export const editBlogSuccess = (payload) => {
  return {
    type: EDIT_BLOG_SUCCESS,
    payload: payload,
  };
};
export const editBlogFailure = (payload) => {
  return {
    type: EDIT_BLOG_FAILURE,
    payload: payload,
  };
};

export const deleteBlog = (payload) => {
  return {
    type: DELETE_BLOGS,
    payload: payload,
  };
};
export const deleteBlogSuccess = (payload) => {
  return {
    type: DELETE_BLOGS_SUCCESS,
    payload: payload,
  };
};
export const deleteBlogFailure = (payload) => {
  return {
    type: DELETE_BLOGS_FAILURE,
    payload: payload,
  };
};

export const getBlogComments = (payload) => {
  return {
    type: GET_BLOG_COMMENTS,
    payload: payload,
  };
};
export const getBlogCommentsSuccess = (payload) => {
  return {
    type: GET_BLOG_COMMENTS_SUCCESS,
    payload: payload,
  };
};
export const getBlogCommentsFailure = (payload) => {
  return {
    type: GET_BLOG_COMMENTS_FAILURE,
    payload: payload,
  };
};
