//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import HomePage from "../../pages/HomePage";
import RequestInfo from "../../pages/RequestInfo";
import History from "../../pages/AboutUs/History";
import Accreditation from "../../pages/AboutUs/Accreditation";
import MissionVision from "../../pages/AboutUs/Mission&Vision";
import SchoolObjectives from "../../pages/AboutUs/SchoolObjectives";
import SchoolPresident from "../../pages/AboutUs/SchoolPresident";
import FacultyStaff from "../../pages/AboutUs/FacultyStaff";
import WhyParam from "../../pages/AboutUs/WhyParam";
import WhoHire from "../../pages/AboutUs/WhoHire";
import Blog from "../../pages/Blog";
import BlogDetails from "../../pages/BlogDetails";
import ContactUs from "../../pages/ContactUs";
import ProjectManagement from "../../pages/Course/ItTraining/ProjectManagement";
import IOSDevelopmentProgram from "../../pages/Course/ItTraining/IOSDevelopmentProgram";
import AgileScrumMasterProgram from "../../pages/Course/ItTraining/AgileScrumMasterProgram";
import CoreJavaDevProgram from "../../pages/Course/ItTraining/CoreJavaDevProgram";
import AWC from "../../pages/Course/AlternateTherapy/AWC";
import AWP from "../../pages/Course/AlternateTherapy/AWP";
import Yoga200 from "../../pages/Course/AlternateTherapy/Yoga200";
import Yoga300 from "../../pages/Course/AlternateTherapy/Yoga300";
import AdvJavaDevProgram from "../../pages/Course/ItTraining/AdvJavaDevProgram";
import MassageTherapy from "../../pages/Course/AlternateTherapy/MassageTherapy";
import MBCS from "../../pages/Course/AHP/MBCS";
import Phlebotomy from "../../pages/Course/AHP/Phlebotomy";
import CCMA from "../../pages/Course/AHP/CCMA";
import CNAToCHHA from "../../pages/Course/AHP/CNAToCHHA";
import CNA from "../../pages/Course/AHP/CNA";
import CHHA from "../../pages/Course/AHP/CHHA";
import PTP from "../../pages/Course/AHP/PTP";
import ECG from "../../pages/Course/AHP/ECG";
import PatientCare from "../../pages/Course/AHP/PatientCare";
import LPN from "../../pages/Course/LPN/LPN";
import ScrollToTopButton from "../ScrollToTopButton";
import AcademicCalendar from "../../pages/StudentResources/AcademicCalendar";
import SchoolCatalog from "../../pages/StudentResources/SchoolCatalog";
import ScheduleAppointment from "../../pages/Admissions/ScheduleAppointment";
import WelcomePie from "../../pages/Admissions/WelcomePie";
import RequirementAndEligibility from "../../pages/Admissions/requirementAndEligibility";
import AdmissionProcess from "../../pages/Admissions/AdmissionProcess";
import AdmissionForm from "../../pages/Admissions/AdmissionForm";
import EnrollmentVerificationLetter from "../../pages/StudentResources/StudentService/EnrollmentVerificationLetter";
import LeaveOfAbsence from "../../pages/StudentResources/StudentService/LeaveOfAbsence";
import WithdrawalApplication from "../../pages/StudentResources/StudentService/WithdrawalApplication";
import TranscriptRequest from "../../pages/StudentResources/StudentService/TranscriptRequest";
import CertificateRequest from "../../pages/StudentResources/StudentService/CertificateRequest";
import Enrollment from "../../pages/StudentResources/StudentService/Enrollment";
import Testimonial from "../../pages/Testimonial/Testimonial";
import CallButton from "../callButton";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsAndCondition from "../../pages/TermsAndCondition";
import ThankyouPage from "../Thankyou/ThankyouPage";
import HealthcarePrograms from "../../pages/Course/HealthcarePrograms";
import WellnessPrograms from "../../pages/Course/WellnessPrograms";
import ITPrograms from "../../pages/Course/ITPrograms";
import GrievanceForm from "../../pages/StudentResources/StudentService/GrievanceForm";

import BlogRoute from "./BlogRoute";
import BlogsMain from "../../pages/AdminPages/Blog/BlogsMain";
import AddBlogs from "../../pages/AdminPages/Blog/AddBlogs";
import ViewBlog from "../../pages/AdminPages/Blog/ViewBlog";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Navigation() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const location = useLocation();
  const refScrollUp = useRef(null);

  const handleScrollUp = () => {
    refScrollUp.current.scrollIntoView({ behavior: "smooth" });
  };

  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  return (
    <div>
      <div ref={refScrollUp}> </div>
      <CallButton />
      <ScrollToTopButton showGoTop={true} scrollUp={handleScrollUp} />
      <Header />
      <Routes>
        <Route path="/" Component={() => <HomePage />} />
        <Route path="/requestInfo" Component={() => <RequestInfo />} />
        <Route path="/historyPIE" Component={() => <History />} />
        <Route path="/accreditation" Component={() => <Accreditation />} />
        <Route path="/mission&vision" Component={() => <MissionVision />} />
        <Route
          path="/schoolobjectives"
          Component={() => <SchoolObjectives />}
        />
        <Route path="/schoolpresident" Component={() => <SchoolPresident />} />
        <Route path="/facultyStaff" Component={() => <FacultyStaff />} />
        <Route path="/whyParam" Component={() => <WhyParam />} />
        <Route path="/hiring" Component={() => <WhoHire />} />
        <Route path="/blog" Component={() => <Blog />} />
        <Route path="/blogDetail/:id" Component={() => <BlogDetails />} />
        <Route path="/contactUs" Component={() => <ContactUs />} />
        <Route
          path="/projectManagement"
          Component={() => <ProjectManagement />}
        />
        <Route
          path="/iOSDevelopment"
          Component={() => <IOSDevelopmentProgram />}
        />
        <Route
          path="/agileScrumMaster"
          Component={() => <AgileScrumMasterProgram />}
        />
        <Route
          path="/coreJavaDeveloperProgram"
          Component={() => <CoreJavaDevProgram />}
        />
        <Route
          path="/advJavaDeveloperProgram"
          Component={() => <AdvJavaDevProgram />}
        />
        <Route path="/AWC" Component={() => <AWC />} />
        <Route path="/AWP" Component={() => <AWP />} />
        <Route path="/yoga200" Component={() => <Yoga200 />} />
        <Route path="/yoga300" Component={() => <Yoga300 />} />
        <Route path="/massageTherapy" Component={() => <MassageTherapy />} />
        <Route path="/mbcs" Component={() => <MBCS />} />
        <Route path="/phlebotomy" Component={() => <Phlebotomy />} />
        <Route path="/CCMA" Component={() => <CCMA />} />
        <Route path="/CNAToCHHA" Component={() => <CNAToCHHA />} />
        <Route path="/CNA" Component={() => <CNA />} />
        <Route path="/CHHA" Component={() => <CHHA />} />
        <Route path="/PTP" Component={() => <PTP />} />
        <Route path="/ecg" Component={() => <ECG />} />
        <Route path="/patientCare" Component={() => <PatientCare />} />
        <Route path="/LPN" Component={() => <LPN />} />
        <Route
          path="/scheduleAppointment"
          Component={() => <ScheduleAppointment />}
        />
        <Route path="/welcomePIE" Component={() => <WelcomePie />} />
        <Route
          path="/requirementAndEligibility"
          Component={() => <RequirementAndEligibility />}
        />
        <Route
          path="/admissionProcess"
          Component={() => <AdmissionProcess />}
        />
        <Route path="/admissionForm" Component={() => <AdmissionForm />} />
        <Route
          path="/academicCalendar"
          Component={() => <AcademicCalendar />}
        />
        <Route path="/schoolCatalog" Component={() => <SchoolCatalog />} />
        <Route
          path="/EnrollmentVerificationLetter"
          Component={() => <EnrollmentVerificationLetter />}
        />
        <Route path="/LeaveOfAbsence" Component={() => <LeaveOfAbsence />} />
        <Route
          path="/WithdrawalApplication"
          Component={() => <WithdrawalApplication />}
        />
        <Route
          path="/TranscriptRequest"
          Component={() => <TranscriptRequest />}
        />
        <Route
          path="/CertificateRequest"
          Component={() => <CertificateRequest />}
        />
        <Route path="/Testimonial" Component={() => <Testimonial />} />
        <Route path="/enrollment" Component={() => <Enrollment />} />
        <Route path="/privacyPolicy" Component={() => <PrivacyPolicy />} />
        <Route
          path="/termsAndconditions"
          Component={() => <TermsAndCondition />}
        />
        <Route path="/thankyou" Component={() => <ThankyouPage />} />
        <Route
          path="/healthcarePrograms"
          Component={() => <HealthcarePrograms />}
        />
        <Route
          path="/wellnessPrograms"
          Component={() => <WellnessPrograms />}
        />
        <Route path="/ITPrograms" Component={() => <ITPrograms />} />
        <Route path="/GrievanceForm" Component={() => <GrievanceForm />} />

        <Route element={<BlogRoute />}>
          <Route path="/BlogsMain" element={<BlogsMain />} />
          <Route path="/addBlog" element={<AddBlogs />} />
          <Route path="/editBlog/:id" element={<AddBlogs />} />
          <Route path="/viewBlog/:id" element={<ViewBlog />} />
        </Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default Navigation;
