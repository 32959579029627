import axios from "axios";
// import { BASE_URL } from '../../../src/global'
import toast from "react-hot-toast";

const NetworkClient = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const service = axios.create({
    headers,
  });

  //   const handleSuccess = (response) => response;

  const handleError = async (error) => {
    // if (error.response && error.response.status === 401) {
    //   sessionStorage.removeItem('token');
    //   window.location.replace('/Signin');
    // }
    if (error.response && error.response.status === 500) {
      toast.error("error");
    } else if (error.response && error.response.status === 400) {
      toast.error("Bad Request");
    }
    return Promise.reject(error);
  };
  const handleSuccess = async (response) => {
    return response;
  };

  service.interceptors.response.use(handleSuccess, handleError);

  service.interceptors.request.use(async (config) => {
    //  const token = sessionStorage.getItem('token');
    // if (token) {
    //   config.headers['Authorization'] = token;
    // }
    config.baseURL = "https://www.paramedu.com:8000/api/";
    // config.baseURL = "http://192.168.1.6:8000/api/";
    // config.baseURL = "http://18.207.208.77/:8000/api/";
    return config;
  });

  const get = async (path, headers) => {
    const response = await service.get(path, { headers });
    return response;
  };

  const patch = async (path, payload, headers) => {
    const response = await service.request({
      method: "PATCH",
      url: path,
      responseType: "json",
      data: payload,
      headers,
    });
    return response;
  };

  const post = async (path, payload, headers) => {
    const response = await service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
      headers,
    });
    return response;
  };

  const deleteRequest = async (path, payload, headers) => {
    const response = await service.request({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: payload,
      headers,
    });
    return response;
  };

  const put = async (path, payload, headers) => {
    const response = await service.request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload,
      headers,
    });
    return response;
  };

  return {
    get,
    patch,
    post,
    delete: deleteRequest,
    put,
  };
};

export default NetworkClient();
