import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_BLOG,
  GET_BLOG_BY_ID,
  GET_RECENT_BLOGS,
  ADD_BLOG_COMMENT,
  GET_BLOG_COMMENTS,
  ADD_BLOG,
  EDIT_BLOG,
  DELETE_BLOGS,
} from "../ActionTypes";
import {
  getBlogAPI,
  getBlogByIdAPI,
  getRecentBlogsAPI,
  addBlogCommentAPI,
  addBlogAPI,
  editBlogAPI,
  deleteBlogAPI,
  getBlogCommentsAPI,
} from "../Api";
import {
  getBlogFailure,
  getBlogSuccess,
  getBlogByIdSuccess,
  getBlogByIdFailure,
  getRecentBlogsSuccess,
  getRecentBlogsFailure,
  addBlogCommentSuccess,
  addBlogCommentFailure,
  addBlogSuccess,
  addBlogFailure,
  editBlogSuccess,
  editBlogFailure,
  deleteBlogSuccess,
  deleteBlogFailure,
  getBlogCommentsSuccess,
  getBlogCommentsFailure,
} from "../actions/blog.action";

function* blogSaga(action) {
  try {
    const response = yield call(getBlogAPI, action.payload);
    if (response) {
      yield put(getBlogSuccess(response.data));
    }
  } catch (error) {
    yield put(getBlogFailure(error));
    console.log("error", error);
  }
}

function* blogByIdSaga(action) {
  try {
    const response = yield call(getBlogByIdAPI, action.payload);
    if (response) {
      yield put(getBlogByIdSuccess(response.data));
    }
  } catch (error) {
    yield put(getBlogByIdFailure(error));
    console.log("error", error);
  }
}

function* recentBlogsSaga(action) {
  try {
    const response = yield call(getRecentBlogsAPI, action.payload);
    if (response) {
      yield put(getRecentBlogsSuccess(response.data));
    }
  } catch (error) {
    yield put(getRecentBlogsFailure(error));
    console.log("error", error);
  }
}

function* addCommentSaga(action) {
  try {
    const response = yield call(addBlogCommentAPI, action.payload);
    if (response) {
      yield put(addBlogCommentSuccess(response.data)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(addBlogCommentFailure(error));
    console.log("error", error);
  }
}

function* addBlogSaga(action) {
  try {
    const response = yield call(addBlogAPI, action.payload);
    // console.log("response", response);

    if (response) {
      yield put(addBlogSuccess(response)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(addBlogFailure(error));
    console.log("error", error);
  }
}

function* editBlogSaga(action) {
  try {
    const response = yield call(editBlogAPI, action.payload);
    // console.log("response", response);

    if (response) {
      yield put(editBlogSuccess(response)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(editBlogFailure(error));
    console.log("error", error);
  }
}

function* deleteBlogSaga(action) {
  try {
    const response = yield call(deleteBlogAPI, action.payload);
    // console.log("response", response);

    if (response) {
      yield put(deleteBlogSuccess(response)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(deleteBlogFailure(error));
    console.log("error", error);
  }
}

function* blogCommentsSaga(action) {
  try {
    const response = yield call(getBlogCommentsAPI, action.payload);
    if (response) {
      yield put(getBlogCommentsSuccess(response.data));
    }
  } catch (error) {
    yield put(getBlogCommentsFailure(error));
    console.log("error", error);
  }
}

export function* blogCommonSaga() {
  yield takeLatest(GET_BLOG, blogSaga);
  yield takeLatest(GET_BLOG_BY_ID, blogByIdSaga);
  yield takeLatest(GET_RECENT_BLOGS, recentBlogsSaga);
  yield takeLatest(ADD_BLOG_COMMENT, addCommentSaga);
  yield takeLatest(ADD_BLOG, addBlogSaga);
  yield takeLatest(EDIT_BLOG, editBlogSaga);
  yield takeLatest(GET_BLOG_COMMENTS, blogCommentsSaga);
  yield takeLatest(DELETE_BLOGS, deleteBlogSaga);
}
