import { React, useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../../hooks/useLoader";
import {
  addBlog,
  addBlogSuccess,
  editBlogSuccess,
  getBlogById,
  editBlog,
} from "../../../redux/actions/blog.action";
import uploadIcon from "../../../assets/images/upload.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";
// or const { useQuill } = require('react-quilljs');

import "quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }], // Font color and background color
    [{ align: [] }],
    ["link", "image"],
    ["clean"], // Clear formatting
  ],
};

const AddBlogs = () => {
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { isLoading, setLoader, Loader } = useLoader();
  const { blogs_add_data, blogs_edit_data } = useSelector(
    (state) => state.BlogReducer
  );
  const blogImageRef = useRef(null);
  const location = useLocation(); // Get the current location (URL)
  const { id } = useParams(); // Extract 'id' from the URL
  const [initialValues, setInitialValues] = useState({
    blogTitle: "",
    blogCategory: "",
    blogDetails: "",
    blogImage: "",
  });
  const isEditMode = location.pathname.includes("editBlog");
  const { quill, quillRef } = useQuill({ placeholder: "123" });

  const validationSchema = Yup.object().shape({
    blogTitle: Yup.string().required("Blog Title is required."),
    blogCategory: Yup.string().required("Please select blog category."),
    blogDetails: Yup.string().required("Please enter blog details."),
    blogImage: Yup.string().required("Blog Image is required."),
  });
  const specificBlogData = useSelector(
    (state) => state.BlogReducer.blog_by_id_data?.data[0]
  );

  useEffect(() => {
    if (id) {
      dispatch(getBlogById(id));
    }
  }, [isEditMode]);

  useEffect(() => {
    if (
      (blogs_add_data && blogs_add_data.status == 200) ||
      (blogs_edit_data && blogs_edit_data.status == 200)
    ) {
      if (blogs_add_data) {
        dispatch(addBlogSuccess(null));
      } else {
        dispatch(editBlogSuccess(null));
      }
      setLoader(false);
      navigate("../BlogsMain");
    }
  }, [blogs_add_data, blogs_edit_data]);
  useEffect(() => {
    if (specificBlogData && isEditMode) {
      setInitialValues({
        blogTitle: specificBlogData.blog_title || "",
        blogCategory: specificBlogData.category_id || "",
        blogDetails: specificBlogData.blog_content || "",
        blogImage: specificBlogData.blog_img || "", // This could be a URL of the existing image
      });
    }
  }, [specificBlogData, isEditMode]);
  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();

    // Append form values to FormData

    console.log(values, "dfdddddddddddd");

    formData.append("blog_title", values.blogTitle);
    formData.append("blog_content", values.blogDetails);
    formData.append("blog_img", values.blogImage);
    formData.append("category_id", values.blogCategory);
    formData.append("blog_published_by", "By Param Institute Of Education");
    if (isEditMode) {
      formData.append("id", id);
    }

    try {
      isEditMode ? dispatch(editBlog(formData)) : dispatch(addBlog(formData));
      resetForm();
      setLoader(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleFileChange = (event, setFieldValue, fieldName) => {
    const file = event.currentTarget.files[0];
    setFieldValue(fieldName, file);
  };
  const coursesCategory = [
    {
      id: 1,
      categoryname: "Business",
    },
    {
      id: 2,
      categoryname: "Education",
    },
  ];
  return (
    <div
      className="max-w-[1600px] m-auto mt-10 font-poppins p-6 flex flex-col"
      ref={formRef}
    >
      <>
        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <>
            <div className="flex items-center justify-between mb-8">
              <p className="text-[20px] font-[500]">
                {isEditMode ? "Edit" : "Add"} Blogs
              </p>
              <p
                className="text-[18px] cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <span>&lt; </span>Back
              </p>
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form className="w-full flex flex-col gap-8">
                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Blog Title <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="blogTitle"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.blogTitle && touched.blogTitle
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="blogTitle"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className=" w-full flex flex-col gap-2 max-w-[100%] md:max-w-[49.4%]">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Blog Category <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        as="select"
                        name="blogCategory"
                        type="text"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.blogCategory && touched.blogCategory
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="" disabled hidden>
                          Select Category
                        </option>
                        {coursesCategory.map((course) => (
                          <option value={course.id}>
                            {course.categoryname}
                          </option>
                        ))}
                      </Field>

                      <ErrorMessage
                        name="blogCategory"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col 991:flex-row">
                    <div className="w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Image <span className="text-[#BB2727]">*</span>
                      </label>
                      <div
                        className={`border-dashed border-[2px] border-[#989898] rounded-[5px] p-3 flex items-center justify-center h-[120px] cursor-pointer overflow-hidden ${
                          errors.blogImage && touched.blogImage
                            ? "border-red-500"
                            : ""
                        }`}
                        onClick={() => blogImageRef.current.click()}
                      >
                        {values.blogImage ? (
                          (console.log(values.blogImage),
                          (
                            <img
                              src={
                                typeof values.blogImage === "string"
                                  ? values.blogImage // If it's already a string (e.g., URL), use it directly
                                  : values.blogImage &&
                                    URL.createObjectURL(values.blogImage) // If it's a file, generate an object URL
                              }
                              alt="Blog Image Preview"
                              className="h-[120px]"
                            />
                          ))
                        ) : (
                          <div className="flex items-center text-[#9F9F9F]">
                            <img
                              src={uploadIcon}
                              alt="Upload Icon"
                              className="w-6 h-6 mr-2"
                            />
                            <span>
                              {blogImageRef.current?.files[0]?.name ||
                                "Upload Document"}
                            </span>
                          </div>
                        )}

                        <input
                          type="file"
                          accept="image/*"
                          id="blogImage"
                          className="hidden"
                          ref={blogImageRef}
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue, "blogImage")
                          }
                        />
                      </div>
                      <ErrorMessage
                        name="blogImage"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <div className="w-full flex gap-2 flex-col">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Blog Details <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field name="blogDetails">
                      {({ field, form }) => (
                        // <CKEditor
                        //   editor={ClassicEditor}
                        //   data={field.value || "SSSS"}
                        //   // config={{
                        //   //   toolbar: [ 'undo', 'redo', 'bold', 'italic', 'numberedList', 'bulletedList' ]
                        //   // }}
                        //   onChange={(event, editor) => {
                        //     const data = editor.getData();
                        //     form.setFieldValue("blogDetails", data);
                        //   }}
                        // />
                        <ReactQuill
                          theme="snow"
                          value={field.value || ""}
                          modules={modules}
                          onChange={(e) => form.setFieldValue("blogDetails", e)}
                        />
                        // <div style={{ width: 500, height: 300 }}>
                        //   <div ref={quillRef} />
                        // </div>
                      )}
                    </Field>
                    <ErrorMessage
                      name="blogDetails"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex gap-3 w-full xl:w-[100%] justify-end mt-3">
                    <button type="submit" className="orangeHoverButton">
                      Publish
                    </button>
                    <button
                      type="button"
                      className="orangeOutlineButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    </div>
  );
};

export default AddBlogs;
